/* ADS-SECTIONS
-----------------------------------------------*/

.section__ads {
  margin: 10px auto;
  max-width: 1500px;



  a, img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .na-jmcontact {
    height: 600px;

    @media (min-width: 992px) {
      height: 584px;
      font-size: 15px;
    }

    @media (min-width: 768px) {
      height: 502px;
    }
  }
}