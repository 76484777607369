/* MOVIE
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.section__movie {
  z-index: 100;
  width: 100%;
  display: block;
  position: relative;
  background-color: @black;

  &__no-video {
    background: url('../../css/img/cloudflare-background.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 8rem 2rem;

    &__title {
      color: white;
      font-size: 2rem;
      margin-bottom: 0;
    }

    &__subtitle {
      color: @primary-color;
    }
  }

  .section__movie-purchase-message {
    display: none;
  }

  .section__movie_wrapper {
    max-width: 1500px;
    margin: 0 auto;
    position: relative;

    .success-msg {
      color: @green;

      a {
        color: @lighten-10-green;
      }
    }

    .section__movie-player {
      overflow: hidden;
      position: relative;

      &.straceo {
        .cta--straceo {
          display: block !important;
        }

        .blured {
          filter: blur(10px);
        }
      }

      &.notloaded {
        .video-js {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
      }

      .cta--straceo {
        padding: 10px 30px;
        border-radius: 5px;
        letter-spacing: 1px;
        display: block;
        text-transform: uppercase;
        position: absolute;
        z-index: 22;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        text-align: center;
        font-size: 16px;
        background: #41cf29;
        color: white;
        border: 2px solid #41cf29;
        width: 80%;
      }


      .section__movie-videos-popup {
        display: none;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        align-items: center;
        z-index: 1;
        height: 100%;
        width: 100%;
        row-gap: .5rem;

        .section__video {
          width: inherit;
        }

        h3 {
          font-size: clamp(1rem, 2vw, 1.3rem);
        }

        &::before {
          content: '';
          z-index: -1;
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          backdrop-filter: blur(10px);
        }

        .section__video-movie-popup {
          margin-right: 1rem;
          margin-left: 1rem;
          row-gap: .5rem;
          column-gap: .5rem;
        }

        .section__button {
          z-index: 1;

          .fa-redo {
            padding-right: 8px;
          }
        }

        @media all and @to-mobile {

          .section__video-item_details_video-infos,
          .section__video-item_details {
            display: none;
          }

          .section__button {
            .button {
              font-size: 0.8rem;
              padding: .5rem;
            }
          }
        }
      }

      .section__movie-videos-popup.active {
        display: flex;
      }
    }

    h1 {
      margin-bottom: 1rem;
      font-size: clamp(1.5rem, 3vw, 2.5rem);
      font-weight: 600;
    }

    .video-js {
      background-size: cover;
      width: 100%;
      padding-top: 56.25%;
      height: auto;
      position: relative;

      .vjs-slider {
        background-color: @fade-20-white;
      }

      .vjs-load-progress {
        background: @fade-20-white;

        div {
          background: @fade-20-white;
        }
      }

      .vjs-control-bar {
        flex-direction: row;
        background-color: @fade-50-black;
        backdrop-filter: blur(10px);
        bottom: -1px;
      }

      .vjs-control {
        place-items: center;
        align-self: center;
      }

      .vjs-big-play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px !important;
        height: 100px !important;
        background-color: @fade-70-primary-color;
        backdrop-filter: blur(10px);
        border: none;
        margin: 0 !important;
        border-radius: 100%;

        @media all and @to-mobile {
          backdrop-filter: blur(5px);
          height: 50px !important;
          width: 50px !important;

          .vjs-icon-placeholder {
            &:before {
              font-size: 1.5rem !important;
            }
          }
        }

        .vjs-icon-placeholder {
          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            place-content: center;
            align-items: center;
            font-size: 3rem;
          }
        }
      }
    }

    .section__movie-buttons-handler {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;

      .button-handler {
        background-color: @fade-10-white;
        padding: 1rem;
        font-weight: 600;
      }

      .button-video-download {
        i {
          color: @green;
        }
      }

      .button-video-favorite {
        i {
          color: @orange;
        }
      }

      .button-video-like {
        i {
          color: @pink;
        }
      }

      .button-video-like.active {
        background-color: @pink;

        i {
          color: @white;
        }
      }

      .button-video-favorite.active {
        background-color: @orange;

        i {
          color: @white;
        }
      }

      .video-like-already {
        display: none;
      }

      .button-video-like-already {
        background-color: red !important;

        @media screen and @desktop {
          .video-like-like, .video-like-number, i {
            display: none;
          }

          .video-like-already {
            display: block;
          }
        }
      }

    }

    .section__movie-infos {
      display: flex;
      column-gap: 1rem;
      overflow: hidden;
      flex-wrap: wrap;

      p {
        opacity: 0.4;
        font-weight: 500;
        margin: 0.5rem 0rem;
      }

      i {
        padding-right: 8px;
      }
    }

    .section__movie-description {
      overflow: hidden;
      position: relative;
      line-height: 15px;
      max-width: 1500px;
      margin: 0 auto;
      padding-bottom: 4rem;
      font-family: 'DM Sans', sans-serif;
      font-weight: normal;
      text-align: justify;
      height: auto;
      max-height: 10rem;

      @media all and @mobile-to-tablet {
        max-height: 8rem;
      }

      @media all and @to-mobile {
        max-height: 8rem;
      }

      p {
        margin: 0;
        line-height: 30px;

        a {
          color: @primary-color;
        }
      }
    }

    .section__movie-categories,
    .section__movie-tags {
      flex-direction: row;

      p {
        opacity: 0.6;
        font-weight: 500;
      }

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 1rem;
        list-style-type: none;

        li {
          position: relative;
          display: block;
          background-color: @primary-color;

          a {
            font-size: 0.8rem;
            position: relative;
            display: block;
            padding: 0.9rem;
          }
        }
      }
    }

    .section__movie-tags {
      ul {
        li {
          background-color: @darken-20-primary-color;

          a {
            opacity: 0.6;
          }
        }
      }
    }

    .section__movie-photos {
      position: relative;
      display: block;

      h2 {
        font-size: clamp(1.2rem, 3vw, 2rem);
        margin-bottom: 1rem;
      }

      .movie-photo-item {
        display: flex;
        flex-direction: column;
        position: relative;

        .overlay-locked-photo {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          text-align: center;
          width: 102%;
          height: 102%;
          display: none;
          flex-direction: column;
          place-content: center;

          p {
            margin-left: 1rem;
            margin-right: 1rem;
            font-size: clamp(0.9rem, 1.5vw, 1rem);
            margin-top: 5px;
            margin-bottom: 0;
          }

          &::before {
            z-index: -1;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.499);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            display: block;
            position: absolute;
          }
        }
      }

      .locked-photo {
        .overlay-locked-photo {
          display: flex;
        }
      }

      .owl-nav {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        .owl-prev,
        .owl-next {
          outline: none;
          width: 5% !important;
          height: 100%;
          position: absolute;
          top: 0%;
          border: 0px solid black;
          font-size: 2rem;
          background-color: transparent;

          @media all and @to-mobile {
            font-size: 1.5rem;
            width: 10%;
          }
        }

        .owl-next {
          right: 0;
          background: rgb(0, 0, 0);
          background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }

        .owl-prev {
          background: rgb(0, 0, 0);
          background: -moz-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }
      }
    }
  }

  @media all and @mobile-to-tablet {

    .section__movie_wrapper {
      h1 {
        text-align: center;
      }
    }

    .section__movie-photos {

      h2 {
        text-align: center;
      }
    }

    .section__movie-purchase-buttons {
      display: flex;
      flex-direction: column;
      text-align: center;
      row-gap: 1rem;

      .button-icon {
        display: none !important;
      }
    }

    .section__movie-buttons-handler {
      .button-handler {
        width: 100%;
        text-align: center;
      }
    }

    .section__movie-purchase-buttons,
    .section__movie-buttons-handler,
    .section__movie-infos,
    .section__movie-description,
    .section__movie-categories,
    .section__movie-tags,
    .section__movie-photos {
      padding-right: 1rem;
      padding-left: 1rem;
      width: auto;
    }
  }

  .section__ads {
    max-width: 1500px;
    margin: 0 auto;
  }
}

.purchased-movie {
  display: block;
  position: relative;

  .section__movie {
    &::before {
      content: '';
      background: @green;
      background: -moz-linear-gradient(0deg, rgba(0, 208, 108, 0) 0%, @green 100%);
      background: -webkit-linear-gradient(0deg, rgba(0, 208, 108, 0) 0%, @green 100%);
      background: linear-gradient(0deg, rgba(0, 208, 108, 0) 0%, @green 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00d06c", endColorstr="#00d06c", GradientType=1);
      height: 50vh;
      width: 100%;
      position: absolute;
      display: block;
    }
  }

  .button-video-download {
    background-color: @green !important;
    color: @white !important;

    i {
      color: @white !important;
    }
  }

  .section__movie-photos {
    .overlay-locked-photo {
      display: none !important;
    }
  }

  .section__movie-purchase-buttons {
    display: none;
  }

  .section__movie-purchase-bar {
    display: none;
  }

  .section__movie-purchase-message {
    text-align: center;
    display: block;
    padding: 0.5rem 0rem;
    margin-bottom: 0.8rem;
    font-weight: 500;
    background-color: @fade-30-black;

    i {
      margin-right: 10px;
    }
  }
}

.section__movie-purchase-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;

  .movie-purchase-button {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    .button-icon {
      display: flex;
      justify-content: center;
      place-self: center;
      place-items: center;
      width: clamp(20%, 11vw, 30%);
      height: 100%;
      background-color: @fade-20-white;

      i {
        font-size: 1.3rem;
      }
    }

    .button-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      place-self: center;
      place-items: center;
      padding: 1rem;

      p {
        margin: 3px;
        font-weight: 500;

        b {
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
    }

    .best-offer-message {
      display: block;
      position: absolute;
      right: 25px;
      top: -10px;
      font-weight: 600;
      padding: 0.2rem 0.5rem;
      background-color: @darken-10-blue;
      text-transform: uppercase;
      font-size: 0.7rem;
      line-height: 14px;
    }
  }

  .button-best-offer {
    background-color: @green;
  }

  .button-trial-offer {
    background-color: @blue;
  }

  .button-buy-only {
    background-color: @grey;
  }

  @media all and @mobile-to-tablet {

    .button-buy-only {
      background-color: @green;
    }

    .button-text {
      text-align: center;

      p {
        font-size: clamp(0.8rem, 1.3vw, 1.3rem);

        b {
          font-size: clamp(0.8rem, 1vw, 1rem);
        }
      }
    }
  }
}

.section__movie-purchase-bar {
  position: relative;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 99;
  width: 100%;

  .title-purchase-bar {
    display: block;
    margin: 0 auto;
    text-align: center;
    text-shadow: 0px 0px 15px @black;
    position: relative;
    font-size: clamp(1.3rem, 3vw, 2rem);
  }

  &::before {
    content: '';
    bottom: 0;
    height: 120%;
    width: 100%;
    position: absolute;
    display: block;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 63%, rgba(0, 0, 0, 0.49343487394957986) 85%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 63%, rgba(0, 0, 0, 0.49343487394957986) 85%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 63%, rgba(0, 0, 0, 0.49343487394957986) 85%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
  }

  .section__movie-purchase-buttons {
    max-width: 1500px;
    margin: 0 auto;
  }

  @media all and @mobile-to-tablet {
    width: 100%;

    .title-purchase-bar {
      margin-right: 1rem;
      margin-left: 1rem;
      font-size: clamp(1rem, 2vw, 2rem);
    }

    .movie-purchase-button {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .button-buy-only {
      background-color: @green;
    }

    .button-best-offer {
      display: none;
    }
  }

  @media all and @to-mobile {

    .button-best-offer,
    .button-trial-offer {
      display: none;
    }
  }
}

.my18pass-blur {
  .section__movie-player {
    &.nsfw {
      padding-top: 56.25%;
      overflow: hidden;

      video {
        display: none;
      }

      .video_detail_play {
        cursor: pointer;
        z-index: 30;
        position: absolute;
        background: 0 0;
        left: 50%;
        top: 50%;
        border: 4px solid white;
        border-radius: 50%;
        width: 8rem;
        height: 8rem;
        .translate(-50%, -50%);
        box-shadow: 0 0 5px 0 #000, inset 0 0 5px 0 #000;
        outline: none;

        &:before {
          content: '\f04b';
          font-family: 'Font Awesome 5 Pro';
          color: #ffffff;
          font-size: 3rem;
          display: block;
          font-weight: bold;
          text-shadow: black 0px 0px 4px;
          position: absolute;
          left: 54%;
          top: 50%;
          .translate(-50%, -50%);
        }
      }
    }
  }
}
