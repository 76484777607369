/* VIDEO-SECTIONS
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.section__video {
    position: relative;

    .section__video-wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        grid-template-areas: "featured featured   video video " "featured featured   video video ";
        row-gap: 8px;
        column-gap: 8px;

        @media all and @tablet-to-desktop {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            grid-template-areas: "featured   featured";
        }

        @media all and @to-mobile {
            grid-template-columns: (1fr);
            grid-template-rows: auto;
            grid-template-areas: "featured";
        }

        .section__video-item {
            aspect-ratio: 16/9;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            width: 100%;

            &:hover {
                .section__video-item_thumb {
                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .section__video-item_details {
                display: flex;
                flex-direction: column;
                position: absolute;
                z-index: 1;
                bottom: -2px;

                .section__video-item_details_video-title {
                    text-align: center;
                    padding-right: 2vw;
                    padding-left: 2vw;
                    font-weight: 500;
                    padding-bottom: 0.5rem;
                    font-size: clamp(0.8rem, 0.9rem, 1rem);
                    line-height: clamp(0.5rem, 5vw, 1rem);
                }

                .section__video-item_details_video-infos {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    span {
                        align-self: center;
                        padding: 0.5rem 0.8rem;
                        background-color: @fade-20-black;
                        backdrop-filter: blur(30px);
                        margin-right: 0.1rem;
                        margin-left: 0.1rem;
                        font-size: 0.6rem;
                        font-weight: 700;

                        i {
                            padding-right: 0.4rem;
                        }
                    }
                }
            }

            .section__video-item_thumb {
                display: flex;
                position: relative;

                img {
                    color: transparent;
                    text-indent: -100%;
                    white-space: nowrap;
                    overflow: hidden;
                    position: absolute;
                    transform: scale(1);
                    transition: all 2s cubic-bezier(.21, 0, 0, 1);
                    width: 100%;
                    top: 0;
                    height: intrinsic;
                }
            }
        }
    }

    .section__video-alternative {
        grid-template-areas: none;
        grid-template-columns: repeat(3, 1fr);

        @media all and @tablet-to-desktop {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: auto;
            grid-template-areas: ".";
        }

        @media all and @to-mobile {
            grid-template-columns: (1fr);
            grid-template-rows: auto;
            grid-template-areas: ".";
        }
    }

    .section__video-movie-popup {
        grid-template-areas: none;
        grid-template-columns: repeat(3, 1fr);
    }

    .free-video, .featured-global-videos {
        overflow: hidden;
        flex-direction: row;
        position: relative;
        z-index: 1;
        display: flex;
        width: auto;

        &::before {
            content: "";
            background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
            width: clamp(10%, 40%, 50%);
            height: auto;
            margin: 3% 0px;
            z-index: 2;
        }

        &::after {
            content: "";
            background: linear-gradient(270deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
            width: clamp(10%, 40%, 50%);
            height: auto;
            margin: 3% 0px;
            z-index: 2;
        }

        @media all and @to-mobile {
            &::before {
                content: "";
                background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
                width: clamp(10%, 15%, 50%);
                height: auto;
                margin: 3% 0px;
                z-index: 2;
            }

            &::after {
                content: "";
                background: linear-gradient(270deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
                width: clamp(10%, 15%, 50%);
                height: auto;
                margin: 3% 0px;
                z-index: 2;
            }
        }

        .free-video-style {
            display: block;
            position: relative;
            width: 100%;
        }

        .free-video-week {
            width: 100%;
            height: auto;
        }

        .free-video-weekend, .featured-global-videos_videos-item {
            row-gap: 30px;
            column-gap: 30px;
            display: flex;
            width: 100%;
            height: auto;

            @media all and @tablet-to-desktop {
                flex-direction: column;
                flex-wrap: wrap;
            }

            @media all and @to-mobile {
                flex-direction: column;
                flex-wrap: wrap;
            }
        }

        .featured-global-videos_videos-item {
            width: 100%;

            .cross-label {
                left: -40%;

                span {
                    font-size: clamp(0.8rem, 2vw, 0.8rem);
                }
            }
        }
    }

    .featured-global-videos {
        &::before {
            content: "";
            background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
            width: clamp(5%, 10%, 20%);
            height: auto;
            margin: 3% 0px;
            z-index: 2;
        }

        &::after {
            content: "";
            background: linear-gradient(270deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
            width: clamp(5%, 10%, 20%);
            height: auto;
            margin: 3% 0px;
            z-index: 2;
        }
    }

    .section__banner-weekends {
        width: 100%;
        position: relative;
        display: flex;

        .banner_weekends-desktop {
            width: 70%;
            margin: 0 auto;
            align-self: center;
        }

        .banner_weekends-mobile {
            width: 100%;
        }
    }

    .tns-outer {
        position: relative;
        overflow: hidden;

        button[data-action="start"], button[data-action="stop"]{
            display: none;
        }

        .prev, .next {
            outline: none;
            width: 7% !important;
            height: 100%;
            position: absolute;
            top: 0%;
            border: 0px solid black;
            font-size: 3rem;
            z-index: 1;
            display: flex;
            place-content: center;

            @media all and @to-mobile {
                width: 15% !important
            }

            &:before {
                margin: 0 auto;
                top: 46%;
                position: absolute;
                color: white;
            }
        }

        .prev {
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }

        .next {
            right: 0;
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }
    }

    .section__video-carousel {
        position: relative;
        display: flex;
        flex-direction: row;

        [data-controls="prev"] {
            background: red;
        }

        [data-controls="next"] {
            background: blue;
        }
    }

    .section__video_pack {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        font-family: 'DM Sans';

        @media all and @to-mobile {
            flex-direction: column;
        }

        .section__video_pack_wrapper {
            width: 100%;
            display: flex;
        }

        .section__video_pack-item {
            position: relative;
            place-self: center;

            .section__video_pack_wrapper {

                img {
                    width: 50%;
                    overflow: auto;
                    box-shadow: @fade-80-black 0px 0px 100px, @fade-80-black 0px 0px 70px, @fade-80-black 0px 0px 50px;

                    &:nth-child(1) {
                        left: 10%;
                        bottom: 0;
                        position: relative;
                        transform: rotate(-8deg) scale(0.8);
                    }

                    &:nth-child(2) {
                        right: 8%;
                        position: relative;
                        transform: rotate(8deg) scale(0.6);
                    }

                    &:nth-child(3) {
                        position: absolute;
                        left: 50%;
                        top: 30%;
                        transform: translate(-50%, 0) rotate(-3deg) scale(0.4);
                    }

                    @media all and @to-mobile {
                        &:nth-child(1) {
                            transform: rotate(-8deg) scale(1);
                        }

                        &:nth-child(2) {
                            transform: rotate(8deg) scale(0.8);
                        }

                        &:nth-child(3) {
                            top: 30%;
                            transform: translate(-50%, 0) rotate(-3deg) scale(0.6);
                        }
                    }
                }
            }
        }

        .section__video_pack-description {
            z-index: 1;
            width: 100%;
            text-align: center;

            @media all and @to-mobile {
                margin-top: 1rem;
            }

            .section__video_pack_wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .section__video_pack-description_title {
                    font-size: clamp(1.2rem, 3vw, 2rem);
                    font-weight: 900;
                    margin-bottom: 0.5rem;
                }

                .section__video_pack-description_link {
                    color: @primary-color;
                    padding-top: 10px;
                    font-style: italic;
                    font-weight: medium;
                    font-size: clamp(1rem, 1.5vw, 1.2rem);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 10px;
                    margin-right: 10px;

                    i {
                        padding-right: 10px;
                        font-style: normal;
                    }
                }
            }

            @media all and @to-mobile {
                .section__video_pack-description_title {
                    text-align: center;
                }

                .section__video_pack-description_link {
                    text-align: center;
                }
            }
        }

        .section__button {
            font-family: 'Montserrat', sans-serif;

            .button {
                background-color: @darken-10-green;
                border: 5px solid @green;
                padding: clamp(1rem, 5vw, 1.5rem) clamp(1.5rem, 5vw, 4rem);
            }
        }
    }
}