/* HEADER
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.dropdown-button {
    position: relative;
    display: inline-block;
    text-align: left;

    &>button {
        outline: none;
        position: relative;
        border: 0;
        padding: 15px 50px 15px 30px;
        overflow: hidden;
        background: none;
        color: white;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .03em;
        line-height: normal;
        text-transform: uppercase;
        z-index: 2;

        &[aria-expanded="true"] {
            color: @primary-color;

            &:before {
                transform: translateY(calc(100% - 3px));
            }

            &:after {
                border-width: 0 5px 5px 5px;
            }
        }

        i {
            margin-right: 8px;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: @primary-color;
            z-index: -1;
            transform: translateZ(0) translateY(0);
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 30px;
            display: block;
            margin-top: -3px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: white transparent transparent transparent;
            opacity: 1;
        }

        &:focus {
            &:before {
                background: @darken-20-primary-color;
            }
        }
    }

    button+ul {
        position: absolute;
        overflow: hidden;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        background: @primary-color;
        list-style: none;
        transform: translateZ(0);
        max-height: 200px;
        visibility: visible;
        z-index: 1;

        &[aria-hidden='true'] {
            max-height: 0;
            visibility: hidden;
            transform: translateZ(0);
        }



        li {
            font-family: 'DM Sans', sans-serif;

            a {
                display: block;
                color: @white;
                padding: 10px 30px;
                font-weight: 400;
                text-decoration: none;
                font-size: 14px;

                &:hover,
                &:focus {
                    background: @fade-10-white;
                }
            }
        }
    }
}