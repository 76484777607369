/* USER PANEL
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.section__user-panel {
    display: initial;
    z-index: 100;
    position: relative;

    h2 {
        font-size: clamp(1.1rem, 2vw, 1.4rem);
        line-height: 30px;

        @media all and @mobile-to-tablet {
            text-align: center;
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }

    .section__title {
        h1 {
            font-size: clamp(1.1rem, 3vw, 2.2rem);
        }
    }

    .section__user-panel_menu-wrapper {
        border-bottom: 2px solid @primary-color;
        border-top: 2px solid @primary-color;
        background-color: @black;

        ul {
            max-width: 1000px;
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            list-style: none;
            justify-content: center;
            width: 100%;

            @media all and @mobile-to-tablet {
                .menu-item {
                    display: flex;

                    i {
                        padding-right: 0;
                    }
                }

                .active {
                    i {
                        padding-right: 8px;
                    }
                }

                span {
                    display: none;
                }
            }

            .menu-item {
                width: 100%;
                display: flex;
                justify-items: center;
                justify-content: center;
                white-space: nowrap;
                padding: clamp(1rem, 2vw, 1.5rem);
                font-weight: clamp(0.9rem, 2vw, 1.3rem);

                &:hover {
                    background-color: @darken-20-primary-color;
                }
            }

            .active {
                background-color: @primary-color  !important;

                span {
                    display: block;
                }
            }

            li {
                font-size: 1rem;
                font-weight: 600;
                text-transform: uppercase;
                align-items: center;
                display: flex;
                width: 100%;

                a {
                    i {
                        padding-right: 8px;
                    }
                }
            }
        }
    }

    .section__user-panel_video-gallery {
        padding-bottom: 10%;
        max-width: 1000px;
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;

        .video_gallery-item {
            display: flex;
            flex-direction: row;
            column-gap: 1rem;

            @media all and @mobile-to-tablet {
                flex-direction: column;
            }

            .video_gallery-item_thumbnail {
                width: 100%;
                position: relative;

                &:hover {
                    span {
                        display: flex;
                    }
                }

                span {
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.2rem;
                    font-weight: 600;
                    width: 100%;
                    height: 100%;
                    z-index: 1;

                    i {
                        font-size: 3rem;
                        padding-bottom: .5rem;
                    }

                    &::before {
                        content: '';
                        z-index: -1;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: @fade-30-black;
                        backdrop-filter: blur(10px);
                    }
                }

                img {
                    width: 100%;
                    position: relative;
                    bottom: -4px;
                }
            }

            .video_gallery-item_details {
                display: flex;
                flex-direction: column;
                row-gap: 1rem;
                justify-content: flex-end;

                @media all and @desktop {
                    width: 35%;
                }

                .purchase-date {
                    color: @green;

                    i {
                        padding-right: 8px;
                    }

                    @media all and @mobile-to-tablet {
                        margin-top: 1rem;
                    }
                }

                .liked-date {
                    color: @pink;

                    i {
                        padding-right: 8px;
                    }

                    @media all and @mobile-to-tablet {
                        margin-top: 1rem;
                    }
                }

                .favorited-date {
                    color: @orange;

                    i {
                        padding-right: 8px;
                    }

                    @media all and @mobile-to-tablet {
                        margin-top: 1rem;
                    }
                }


                h3 {
                    font-size: clamp(1rem, 2vw, 1.3rem);
                    font-weight: 600;
                    line-height: 25px;
                }

                .video_gallery-item_button-wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    row-gap: 1rem;

                    .button-handler {
                        background-color: @fade-10-white;
                        padding: 1rem;
                        font-weight: 600;
                        position: relative;
                        display: flex;
                        white-space: nowrap;
                        width: max-content;

                        i {
                            padding-right: 8px;
                        }

                        span {
                            display: block;
                        }
                    }

                    .button-video-download {
                        i {
                            color: @green;
                        }
                    }

                    .button-video-favorite {
                        i {
                            color: @orange;
                        }
                    }

                    .button-video-like {
                        i {
                            color: @pink;
                        }
                    }

                    .active.button-video-like {
                        color: @white;
                        background-color: @pink;

                        i {
                            color: @white;
                        }
                    }

                    .active.button-video-favorite {
                        color: @white;
                        background-color: @orange;

                        i {
                            color: @white;
                        }
                    }

                    @media all and @mobile-to-tablet {
                        flex-direction: row;
                        column-gap: 1rem;

                        .button-handler {
                            justify-content: center;
                            width: 10%;

                            i {
                                padding-right: 0;
                            }

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
