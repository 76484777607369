/* RESPONSIVE
-----------------------------------------------*/

@tablet-to-desktop: ~'(max-width: 1400px)';
@mobile-to-tablet: ~'(max-width: 900px)';
@to-mobile: ~'(max-width: 800px)';

@desktop: ~'(min-width: 1400px)';
@tablet: ~'(min-width: 800px) and (max-width: 1399.98px)';
@mobile: ~'(min-width: 0px) and (max-width: 799.98px)';
@mobile-small: ~'(max-height: 650px)';

.hide-desktop {
    @media screen and @desktop {
        display: none !important;
    }
}

.hide-tablet {
    @media screen and @tablet {
        display: none !important;
    }
}

.hide-mobile {
    @media screen and @mobile {
        display: none !important;
    }
}

.hide-mobile-small {
    @media screen and @mobile-small {
        display: none !important;
    }
}