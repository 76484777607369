/* FORMS
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";
@import (less) "../mixins/fonts.less";

.section__form {
    margin-bottom: 10%;
    max-width: 600px;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;

    .section__title {
        h1 {
            font-size: clamp(1.1rem, 3vw, 2.2rem);
        }
    }
}

.section__form_wrapper {
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;

    .section__form-form {
        background: @darken-20-primary-color;
        padding: 25px;
        border: solid 2px @primary-color;
        display: flex;
        flex-direction: column;
        position: relative;

        fieldset {
            border: medium none !important;
            min-width: 100%;
            padding: 0;
            width: 100%;

            &:last-child {
                margin: 0;
            }
        }

        select {
            color: @fade-70-white;
            font-size: clamp(1rem, 2vw, 1.1rem);
            font-weight: 600;
            background-color: @darken-20-primary-color;
            font-family: 'DM Sans', sans-serif;

            option {
                color: white;
                font-family: 'DM Sans', sans-serif;
                background-color: none;
            }
        }

        label {
            font-size: clamp(1rem, 2vw, 1.1rem);
            margin-top: .5rem;
            margin-bottom: .5rem;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 25px;
        }

        textarea,
        input {
            box-sizing: border-box;
            width: 100%;
            resize: vertical;
            padding: 1rem;
            background: @darken-30-primary-color;
            color: @white;
            font-weight: 600;
            outline: none;
        }

        input[type="file"] {
            color: @fade-70-white;
        }


        .custom-file-input {
            padding: 0;
            text-align: left;
            overflow: hidden;
            resize: none;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: content-box;

            &::-webkit-file-upload-button {
                visibility: hidden;
            }

            &::before {
                text-align: left;
                position: relative;
                padding: 1rem;
                content: 'Sélectionnez un fichier';
                display: inline-block;
                color: @white;
                background-color: @primary-color;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                cursor: pointer;
                font-weight: 700;
                font-size: clamp(0.7rem, 1vw, 1rem);
                margin-right: -7rem;
            }
        }

        .form-input {
            height: 3rem;
        }

        .form-text-info {
            font-size: clamp(0.6rem, 1vw, 0.8rem);
            font-weight: 500;
            color: white;
            opacity: 0.4;
            padding-top: .5rem;
            display: block;
        }

        textarea[type="message"] {
            height: 100px;
            width: 100%;
            min-width: 100%;
            resize: vertical;
        }
    }

    button[type="submit"] {
        border: solid 2px @lighten-10-primary-color;
        margin-top: 1rem;
        cursor: pointer;
        display: block;
        background: @primary-color;
        color: #FFF;
        padding: 1.5rem;
        font-size: 15px;
    }

    button[type="submit"]:hover {
        background: @lighten-10-primary-color;

    }

    .info-message {
        text-align: center;
        font-weight: 600;

        a {
            color: @lighten-10-primary-color;

            &:hover {
                color: @primary-color;
            }
        }
    }

    .pressMessage {
        margin: 1rem auto;
    }

    .errorlist {
        color: @orange;
        font-size: clamp(0.7rem, 1vw, 0.8rem);
        margin: 0.5rem 0rem 1rem 0rem;
    }
}

.section__form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 1rem;

    .errorlist {
        margin-bottom: 0;
    }

    @media all and @mobile-to-tablet {
        flex-direction: column;
        .g-recaptcha {
            margin: 0 auto;
        }
    }

    .g-recaptcha {
        padding-top: 15px;
    }

    button {
        font-size: clamp(1rem, 2vw, 1.3rem);
        font-weight: bold;
        text-transform: uppercase;

        i {
            padding-right: 8px;
        }
    }
}

.section_register-features {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    h2 {
        color: @lighten-10-primary-color;
        text-align: center;
        padding: 1rem 0rem;
        text-transform: uppercase;
        font-size: clamp(1rem, 2vw, 1.3rem);
    }

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        li {
            line-height: 20px;
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
            padding-right: 1rem;
            padding-left: 1rem;

            @media all and @mobile-to-tablet {
                flex-direction: column;
                text-align: center;
                margin-bottom: 2rem;

                i {
                    padding-right: 0;
                    font-size: 1.3rem;
                    padding-bottom: .3rem;
                }

            }

            i {
                padding-right: 8px;
                color: @lighten-10-primary-color;
            }
        }
    }
}