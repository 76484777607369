/* BROWSERS FIXES
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

/*SAFARI*/

@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (display:flow-root) {

        .header__menu {
            .header__menu_wrapper-links {
                a {
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                .header__menu_wrapper_video-item {
                    a {
                        position: relative;

                    }
                }
            }
        }

        .section__movie-categories ul li,
        .section__movie-tags ul li,
        .button-handler {
            margin: .5rem !important;
        }

        .section__movie_wrapper {
            .section__movie-purchase-buttons {
                .movie-purchase-button {
                    margin-bottom: .2rem;
                    margin-top: .2rem;
                }
            }
        }

        .section__movie-infos {
            p {
                margin-right: .7rem !important;
            }
        }
    }
}