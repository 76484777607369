
.modal--offer {
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;

  --main-color: @pink;
}

.offer {
  display: grid;
  grid-template-columns: 250px auto;
  width: 700px;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  color: black;

  &__content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__cta {
    text-align: center;
    padding: 10px;
    background: var(--main-color);
    border-radius: 20px;
    color: white;
    border: none;

    &:hover {
      color: white;
    }
  }

  &__close {
    text-align: center;
    color: #7b7b7b;
    width: 100%;
    padding: 10px;
    border: none;
    background: transparent;
  }

  &__text {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 30px;
    color: #b5b5b5;
  }

  &__subtitle {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    color: var(--main-color);
    font-style: italic;
    letter-spacing: 1px;
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    font-weight: bold;
    margin: 0;
    font-size: 35px;
    margin-bottom: 1rem;
  }

  &__img {
    background: var(--main-color);
    display: grid;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;

    img {
      display: block;
      width: calc(100% + 50px);
      height: 300px;
      object-fit: contain;
      object-position: bottom right;
      margin-top: 50px;
      margin-bottom: -1px;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    transform: none;
    left: 0;
    top: auto;
    bottom: 0;
    border-radius: 0;
    max-height: 100%;

    &__content {
      padding: 20px;
    }

    &__img img {
      width: 100%;
      object-position: inherit;
      height: 150px;
      margin-top: 20px;
    }
  }
}