/*
 _____   _____   _____   _   __   _   _   _   _   _____   _   _____   _   _____   __   _
|  _  \ | ____| /  ___/ | | |  \ | | | | | | | | |  _  \ | | |_   _| | | /  _  \ |  \ | |
| | | | | |__   | |___  | | |   \| | | |_| | | | | |_| | | |   | |   | | | | | | |   \| |
| | | | |  __|  \___  \ | | | |\   | |  _  | | | |  _  { | |   | |   | | | | | | | |\   |
| |_| | | |___   ___| | | | | | \  | | | | | | | | |_| | | |   | |   | | | |_| | | | \  |
|_____/ |_____| /_____/ |_| |_|  \_| |_| |_| |_| |_____/ |_|   |_|   |_| \_____/ |_|  \_|

*/

/* VENDORS
-----------------------------------------------*/
@import (less) "../vendors/lesshat/build/lesshat.less";
@import (css) "../vendors/normalize-css/normalize.css";
@import (css) "../vendors/fontawesome-pro-5.14.0-web/css/all.css";
@import "../vendors/lity/lity.less";
@import (css) "../vendors/light-gallery/css/lightgallery.css";
@import (css) "../../webpack/node_modules/tiny-slider/dist/tiny-slider.css";

/* MIXINS
-----------------------------------------------*/
@import "mixins/fonts";
@import "mixins/colors";
@import "mixins/theme";
@import "mixins/responsive";
@import "mixins/global-components";
@import "mixins/empty-base";

/* COMPONENTS
-----------------------------------------------*/
@import "components/top-banner-websites";
@import "components/promo-widgets";
@import "components/header";
@import "components/footer";
@import "components/dropdown";
@import "components/disclaimer";
@import "components/modal-offer";
@import "components/userstate";
@import "components/dalenys-modal";

/* SECTIONS
-----------------------------------------------*/
@import "sections/section-ads";
@import "sections/section-video";
@import "sections/section-category";
@import "sections/section-seo";
@import "sections/section-movie";
@import "sections/section-forms";
@import "sections/section-user-panel";
@import "sections/section-common-pages";

/* INIT
-----------------------------------------------*/
@import "init/browsers-fixes";
