.main {
  width: 100vw;
  height: 100vh;

  &--centered {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  &__container {
    @media screen and @desktop {
      width: 500px;
      max-width: 100%;
    }
  }
}

.card {
  padding: 3rem;
  background: #2f2f2f;
  border-radius: .5rem;
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 2rem;

  &__title {
    text-align: center;
    margin: 0;
    color: @white;
    font-family: "OpenSans", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
  }

  &__description {
    color: white;
    text-align: center;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 1rem;
    border-top: 1px solid #444;
  }

  &__logo {
    display: block;
    width: 20rem;
    max-width: 90%;
    margin: 0 auto;

    img {
      width: 100%;
      display: block;
    }
  }

  &__form {
    display: contents;
  }

  &__cta {
    text-align: center;
    display: block;
    background: transparent;
    border: none;
    color: @lighten-20-primary-color;
    padding: 1rem;
    text-decoration:none;
    max-width:20rem;
    margin: 0 auto;

    &:hover, &:focus, &:active {
      color:inherit;
    }

    &--main {
      color:white;
      background-color: @primary-color;
      border-radius: .5rem;
    }
  }
}
