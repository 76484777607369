/* HEADER
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";
@import "plq.less";

.header {
    z-index: 101;
    display: block;
    width: 100%;
    top: 0;
    position: fixed;
    width: 100vw;
    background: @black;
    max-height: 52px;

    a {
        color: white;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;
        align-items: center;

        i {
            margin-right: 0.5rem;
        }
    }

    .header__container {
        min-height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .header__container_wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            min-height: 50px;
            white-space: nowrap;

            @media all and @mobile-to-tablet {
                max-width: inherit;

                a {
                    i {
                        margin: 0 0.2rem;
                    }
                }
            }


            .header__container_wrapper-search {
                width: 90%;
                max-width: 500px;
                overflow: hidden;

                @media all and @mobile-to-tablet {
                    max-width: inherit;
                }

                .search-bar {


                    form {
                        background: @fade-10-white;
                        height: 50px;
                        max-height: 50px;
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        padding-left: 1rem;
                    }

                    .search-bar_input {
                        height: 100%;
                        width: 100%;
                        color: @white;
                        font-style: italic;
                        font-size: clamp(0.8rem, 2vw, 1rem);
                        text-overflow: ellipsis;
                    }

                    .search-bar_input_button {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        height: 100%;
                        position: relative;
                        color: @white;
                        background: @fade-10-white;
                    }
                }
            }

            .header__container_wrapper-item {
                align-self: center;
                display: flex;
                flex-direction: row;

            }

            .header__container-item-menu-logo {
                padding-bottom: 7px;

                img {
                    max-height: 26px;
                    height: 100%;
                    width: 100%;
                }
            }

            .header__container-item-menu-status {
                padding-right: 2rem;
                cursor: pointer;
            }
        }
    }

    // &::after {
    //     content: "";
    //     position: absolute;
    //     width: 100vw;
    //     height: 100vh;
    //     z-index: -1;
    //     background-color: @fade-80-black;
    // }
}


.header__menu {
    transition: all .5s cubic-bezier(.21, 0, 0, 1);
    z-index: 101;
    display: block;
    width: 100%;
    max-width: 500px;
    position: fixed;
    height: 100vh;
    overflow: hidden;
    left: -500px;
    top: 0;
    background-color: @black;

    a {
        color: white;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;

        i {
            margin-right: 0.5rem;
        }
    }

    .header__menu_wrapper {
        border-right: solid 2px @primary-color;
        top: 0;
        background: @black;
        max-height: 52px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 50px;

        .header__container-item-menu-status {
            cursor: pointer;
        }

        .header__container-item-menu-logo {
            padding-bottom: 7px;

            img {
                max-height: 26px;
                height: 100%;
            }
        }
    }

    .header__menu_wrapper-links {
        border-right: solid 2px @primary-color;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        overflow: scroll;

        a {
            border-bottom: 2px solid @primary-color;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        .header__menu_wrapper-links_wrapper {
            display: flex;
            flex-direction: row;

            a {
                width: 100%;
                position: relative;
                place-content: center;
                padding: 2rem 0rem;

                i {
                    width: 20px;
                }
            }
        }

        .header__menu_wrapper_video-item {
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 50px;

            a {
                border: none;
                padding: 0;
            }

            .header__menu_wrapper_video-item_details {
                height: 20%;
                bottom: 0;
                display: flex;
                width: 100.2%;
                flex-direction: column;
                place-content: flex-end;

                .header__menu_wrapper_video-title {
                    padding: 1rem 1rem;
                    font-size: 0.8rem;
                    text-align: center;
                }
            }

            .header__menu_wrapper_video-item_thumb {
                img {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: -1;
                }
            }
        }
    }

    @media all and @to-mobile {
        max-width: 100%;

        .header__menu_wrapper {
            border-right: none;
        }

        .header__menu_wrapper-links {

            border-right: none;
        }
    }

    @media all and @mobile-to-tablet {
        max-width: 500px;
    }
}

.background-menu-close {
    transition: all .5s cubic-bezier(.21, 0, 0, 1);

    &::before {
        content: "";
        position: absolute;
        width: calc(100vw + 500px);
        height: 100vh;
        z-index: -1;
        background-color: @fade-80-black;
    }
}

.menu-open {
    left: 0px !important;
    overflow: visible;
}

.header__search-bar {
    background-color: @black;
    position: absolute;
    top: -52px;
}

.hide-menu-mobile {
    display: none !important;
}

.search-bar-open {
    top: 0px !important;
    overflow: visible;
    position: relative;
    display: flex !important;
}