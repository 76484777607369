/* COMMON PAGES
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.section__common-pages {
    .section__title {
        h1 {
            font-size: clamp(1.1rem, 3vw, 2.2rem);
        }
    }

    .section__common-pages_wrapper {
        margin: 0 auto;
        max-width: 1000px;
        padding-right: 1rem;
        padding-left: 1rem;

        a {
            color: @lighten-10-primary-color
        }

        ul {
            list-style-type: square;
            padding-left: 2rem;
        }

        strong {
            color: @lighten-10-primary-color;
            text-decoration: underline;
        }

        .identity {
          margin: 2rem auto;
          text-align: justify;

          img {
            height: .6rem;
            vertical-align: baseline;
          }
        }

        .rd {
          direction: rtl;
          unicode-bidi: bidi-override;
        }
    }
}
