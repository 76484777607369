/* GLOBAL-COMPONENTS
-----------------------------------------------*/

/* FIX SCROLLBARS*/

.placeholder {
  padding-top: calc(9 / 16 * 100%);
  width: 100%;
  height: 0;

  &:before {
    left: 0;
    top: 0;
    -webkit-transition: all 0;
    -moz-transition: all 0;
    -ms-transition: all 0;
    -o-transition: all 0;
    transition: all 0;
    position: absolute;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    background-image: url("../img/placeholder-desinhibition.svg");
    z-index: -1;
    height: 100%;
    //height: -webkit-fill-available;
  }
}

.item-padding {
  padding: clamp(0.6rem, 2vw, 1rem) clamp(1rem, 2vw, 2rem);
  overflow: hidden;
  display: flex;
}

.item-padding-horizontal {
  padding: 0 clamp(1rem, 2vw, 2rem);
}

.item-no-padding-right {
  padding-right: 0;
}

.item-no-padding-left {
  padding-left: 0;
}

.section__button {
  display: grid;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  .button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: clamp(1rem, 5vw, 1rem) clamp(1rem, 5vw, 3rem);
    background: @darken-20-primary-color;
    border: 2px solid @primary-color;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    transition: all .5s cubic-bezier(.21, 0, 0, 1);
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
      background: @primary-color;
    }
  }
}

.featured-video {
  grid-area: featured;
}

.section__video-alternative {
  .cross-label {
    background-color: @green;
  }
}

.cross-label {
  position: absolute;
  z-index: 2;
  background-color: @primary-color;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  transform: rotate(-45deg);
  left: -40%;
  top: 10%;
  border: 2px solid @fade-10-black;

  @media all and @to-mobile {
    left: -40%;
    top: 13%;
  }

  @media all and @mobile-to-tablet {
    left: -40%;
    top: 13%;
  }

  span {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: clamp(0.6rem, 2vw, 0.8rem);

    @media all and @to-mobile {
      padding-top: clamp(0.2rem, 0.5rem, 0.8rem);
      padding-bottom: clamp(0.2rem, 0.5rem, 0.8rem);
    }

    @media all and @mobile-to-tablet {
      padding-top: clamp(0.2rem, 0.5rem, 1rem);
      padding-bottom: clamp(0.2rem, 0.5rem, 1rem);
    }
  }
}

.video_background-gradient {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  padding-top: 10%;
  border: none;
  overflow: hidden;
  width: 102%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.section__title {
  display: block;
  position: sticky;
  z-index: 2;
  top: 50px;

  .section__title-side {
    text-align: left;
    padding-left: 1rem;

    &::before {
      content: "";
      background-color: @primary-color;
      height: clamp(0.8rem, 2.2vw, 1.1rem);
      width: 10px;
      margin: 0 1rem;
    }

    &::after {
      content: "";
      background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
      height: clamp(0.8rem, 2.2vw, 1.1rem);
      width: 40%;
      margin: 0 1rem;
    }
  }

  .section__title-center {
    justify-content: center;
    text-align: center;
    width: 100%;

    &::before {
      content: "";
      background: linear-gradient(270deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
      height: clamp(0.8rem, 2.2vw, 1.1rem);
      width: 20%;
      margin: 0 1rem;
    }

    &::after {
      content: "";
      background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
      height: clamp(0.8rem, 2.2vw, 1.1rem);
      width: 20%;
      margin: 0 1rem;
    }
  }

  @media all and @to-mobile {
    .section__title-side {
      text-align: center;
      justify-content: center;
      padding-left: 0;

      &::before {
        content: "";
        background: linear-gradient(270deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
        height: clamp(0.8rem, 2.2vw, 1.1rem);
        width: clamp(10%, 15%, 20%);
        margin: 0 1rem;
      }

      &::after {
        content: "";
        background: linear-gradient(90deg, @primary-color 0%, rgba(169, 21, 41, 0) 100%);
        height: clamp(0.8rem, 2.2vw, 1.1rem);
        width: clamp(10%, 15%, 20%);
        margin: 0 1rem;
      }
    }
  }

  h1 {
    place-items: center;
    display: flex;
    flex-direction: row;
    font-size: clamp(0.8rem, 1.8vw, 1.4rem);
    text-transform: uppercase;
    z-index: 1;
    margin: 0;
    margin-bottom: 0;
    margin-top: 0;
    position: sticky;
    background-color: @black;
    top: -1px;
    padding: 1rem 0;
  }

  h2 {
    place-items: center;
    display: flex;
    flex-direction: row;
    font-size: clamp(1.1rem, 3vw, 2.2rem);
    text-transform: uppercase;
    z-index: 1;
    margin: 0;
    margin-bottom: 0;
    margin-top: 0;
    position: sticky;
    background-color: @black;
    top: -1px;
    padding: 1rem 0;
  }
}

.center {
  margin: 0 auto;
  text-align: center;
}


.get-premium {
  padding-left: 1rem;
  height: 0;
  position: relative;
  margin: 0 auto;
  padding-top: calc(149 / 978 * 100%);
    max-width: 1500px;

  @media all and @tablet-to-desktop {
    margin-top: 1rem;
    padding-left: 1.5rem;
    padding-top: calc(289 / 708 * 100%);

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  a {
    display: block;
  }

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}


@supports (-webkit-line-clamp: 3) {
  .show-hide-text p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .show-hide-text p:after {
    display: none;
  }
}

.show-less {
  display: none;
}

.show-less:target {
  display: block;
}

.show-less:target ~ p {
  display: block;
  max-height: 100%;
}

.show-less:target + a {
  display: none;
}

.wrapper {
  max-width: 1500px;
  margin: 0 auto;
}

.wrapper p {
  font-size: 16px;
  line-height: 20px;
}

.section__infos {
  max-width: 1500px;
  margin: 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;

  .section__title {
    .section__title-side {
      padding-left: 0;
    }
  }

  .section__seo {
    line-height: 15px;
    padding-bottom: 2rem;
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    max-height: 5rem;

    .section__show-more {
      padding-top: 40px;
    }
  }

  p {
    font-size: 0.8rem;
  }
}

.section__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: solid @primary-color 2px;

  .section__wrapper-filters {
    transition: all 0.5s cubic-bezier(0.21, 0, 0, 1);
    height: 100%;
    min-width: 370px;
    position: sticky;
    top: 50px;
    overflow: scroll;
    z-index: 10;
    background-color: @black;
    border-right: solid @primary-color 2px;

    @media all and @tablet-to-desktop {
      position: fixed;
      left: -400px;
    }

    @media all and @mobile-to-tablet {
      position: fixed;
      min-width: 0;
    }

    a,
    label {
      &:hover {
        color: white !important;
      }
    }

    .section_filter-button {
      border-top: none;
      position: sticky;
      top: 0;
    }

    .fas {
      padding-right: 8px;
    }

    ul {
      list-style-type: none;


      li {
        padding-bottom: 10px;
        font-weight: 500;


        a {
          color: @fade-50-white;
        }
      }

      .active {
        a {
          color: @white;

          i {
            color: @primary-color;
          }

          .fa-eye {
            color: @orange;
          }

          .fa-heart {
            color: @pink;
          }

          .fa-clock {
            color: @green;
          }
        }
      }
    }

    .bloc-filter {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 1rem;
      padding-left: 2rem;

      &::after {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        right: 0;
        background: @primary-color;
        background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, @primary-color 100%);
      }

      .bloc-filter_title {
        font-weight: bold;
        margin: 0.4rem 0;
      }

      .bloc-filter_wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        .bloc-filter_promo {
          color: @green;
          margin-bottom: 0.2rem;
        }

        .bloc-filter_search-bar {
          background: @fade-10-white;
          display: flex;
          flex-direction: row;
          height: 30px;
          margin-left: 10px;

          .search-bar_input {
            height: 100%;
            width: 100%;
            color: @white;
            font-style: italic;
            font-size: clamp(0.8rem, 1vw, 0.9rem);
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          .search-bar_input_button {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            color: @white;
            background: @fade-10-white;
          }
        }
      }

      .sticky-slide {
        color: white;
        height: 55vh;
        max-height: 1000px;
        border-radius: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 0.5rem;
        max-width: 350px;

        @media all and @mobile-to-tablet {
          height: 30vh;
        }

        > dl {
          margin: 0 0 1rem;
          display: grid;
          grid-template-columns: 4ex 1fr;
          -webkit-box-align: center;
          align-items: center;
        }

        > dl dt {
          position: -webkit-sticky;
          position: sticky;
          top: .5rem;
          display: -webkit-inline-box;
          display: inline-flex;
          -webkit-box-align: center;
          align-items: center;
          height: 4ex;
          font-weight: bold;
        }

        > dl dd {
          text-overflow: ellipsis;
          grid-column: 2;
          margin: 0;
          padding: 0.3rem;
          white-space: pre;
          overflow: hidden;
          display: flex;

          a {
            color: @fade-50-white;
          }

          label {
            cursor: pointer;
            font-size: 1rem;
            color: @fade-50-white;

            input {
              display: none;
            }

          }

          span {
            color: @primary-color;
            padding-left: 8px;
          }
        }

        > dl:first-of-type > dd:first-of-type {
          margin-top: .4rem;
        }
      }

      .element__checkbox {
        position: relative;

        p {
          margin: 0.3rem 0;
        }

        input {
          display: none;
        }

        label, a {
          font-size: 0.9rem;
        }

        a {
          display: flex;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
          position: absolute;
        }

        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:not(:checked) + a,
        [type="checkbox"]:checked + label,
        [type="checkbox"]:checked + a {
          padding-left: 1.95em;
          cursor: pointer;
        }

        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:not(:checked) + a:before,
        [type="checkbox"]:checked + label:before,
        [type="checkbox"]:checked + a:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 1.10em;
          height: 1.10em;
          border: 2px solid @primary-color;
          background: @darken-20-primary-color;
        }

        /* checked mark aspect */

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:not(:checked) + a:after,
        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:checked + a:after {
          content: '\2714';
          position: absolute;
          top: .15em;
          left: .22em;
          font-size: 1rem;
          line-height: 1;
          color: @white;
          transition: all ease-in-out .2s;
          font-family: 'DM Sans', 'Arial Unicode MS', Arial;
        }

        /* checked mark aspect changes */

        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:not(:checked) + a:after {
          opacity: 0;
          transform: scale(0);
        }

        [type="checkbox"]:checked + label:after,
        [type="checkbox"]:checked + a:after {
          opacity: 1;
          transform: scale(0.8);
        }

        /* disabled checkbox */

        [type="checkbox"]:disabled:not(:checked) + label:before,
        [type="checkbox"]:disabled:not(:checked) + a:before,
        [type="checkbox"]:disabled:checked + label:before,
        [type="checkbox"]:disabled:checked + a:before {
          box-shadow: none;
          border: 2px solid @primary-color;
          background: @darken-20-primary-color;
        }

        [type="checkbox"]:disabled:checked + label:after,
        [type="checkbox"]:disabled:checked + a:after {
          color: @grey;
        }

        [type="checkbox"]:disabled + label,
        [type="checkbox"]:disabled + a {
          color: @grey;
        }
      }
    }

    .bloc-filter {
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .section__wrapper-filters.filter-open {
    @media all and @mobile-to-tablet {
      position: fixed;
      min-width: 0;
      width: calc(100% + 2px);
    }
  }

  .section__wrapper-elements {
    position: relative;
    width: 100%;

    .fix-carousel-position {
      position: relative;
      height: 30vh;
      display: contents;
    }

    .section__video-carousel {
      position: absolute;

      @media all and @tablet-to-desktop {
        position: relative;

      }
      @media all and @mobile-to-tablet {
        position: relative;

      }
      @media all and @to-mobile {
        position: relative;
      }
    }
  }

  .filter-open {
    left: 0;
  }

  @media all and @mobile-to-tablet {
    column-count: 4;
  }

  @media all and @to-mobile {
    column-count: 2;
  }
}

.section__show-more {
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 80px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%, rgba(0, 0, 0, 1) 100%);

  span {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    cursor: pointer;
    text-align: center;
    color: @white;
    line-height: 20px;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: bold;
  }

  .show_text-less {
    display: none;
  }

  .show_text-more {
    display: block;
  }
}

.section__seo.active, .section__movie-description.active, .section__disclaimer-csa.active {
  height: auto;
  max-height: max-content !important;

  .section__show-more {
    padding-top: 0px;
    background: none;
  }

  .show_text-less {
    display: block;
  }

  .show_text-more {
    display: none;
  }
}

.pagination-live {
  display: block;
  list-style-type: disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  margin-top: 2rem;

  row-gap: 0.5rem;
  place-content: center;

  li {
    margin-right: 2px;
    margin-left: 2px;
    display: block;
    display: flex;
    align-items: center;

    a, input {
      position: relative;
      color: @white;
      text-decoration: none;
      background-color: #1a1a1a;
      border: 0;
      width: 40px;
      height: 40px;
      display: flex;
      place-items: center;
      justify-content: center;

      &:hover,
      &:active, &:focus {
        background-color: @darken-10-primary-color;
        color: @white;
      }
    }

    span {
      position: relative;
      float: none;
      padding: 0.5rem 0.7rem;
      color: @white;
      text-decoration: none;
      margin: -7px;
      border-radius: 0.5rem;
      border: 0;
      background: transparent;

      &:hover, &:active, &:focus {
        background: transparent;
        color: @white;
      }
    }

    input {
      background-color: @lighten-10-primary-color;
      text-align: center;
      color: @white;
    }


    &:first-child a, &:last-child a {
      background-color: @primary-color;
      color: @white;
      border: 1px solid #a91529;
    }

    &.active {
      a {
        background-color: @darken-20-primary-color;
        color: @grey;

        &:hover, &:active, &:focus {
          background-color: @darken-10-primary-color;
          color: @white;
        }
      }
    }
  }

  @media all and @to-mobile {
    margin-bottom: 2rem;

    .ghost {
      display: none;
    }

    li {
      a, input {

        width: 35px;
        height: 35px;

      }
    }
  }
}

.pagination-mobile {
  display: none;
}

@media all and @to-mobile {

  .pagination-mobile {
    list-style-type: disc;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 1rem;
    margin-right: 1rem;
    row-gap: 0.5rem;
    place-content: center;
    margin-bottom: 3rem;

    .ghost {
      display: none;
    }


    li {
      margin-right: 2px;
      margin-left: 2px;
      display: block;
      display: flex;
      align-items: center;

      a, input {
        position: relative;
        color: @white;
        text-decoration: none;
        background-color: transparent;
        border-radius: 0.5rem;
        border: 0;
        width: 20px;
        height: 20px;
        display: flex;
        place-items: center;
        justify-content: center;
      }

      span {
        position: relative;
        float: none;
        padding: 0.5rem 0.7rem;
        color: @white;
        text-decoration: none;
        margin: -7px;
        border-radius: 0.5rem;
        border: 0;
        background: transparent;

        &:hover, &:active, &:focus {
          background: transparent;
          color: @white;
        }
      }

      input {
        background-color: @white;
        text-align: center;
        color: @grey;
      }


      &:first-child a, &:last-child a {
        color: @white;
      }

      &.active {
        a {
          background-color: @primary-color;
          color: @white;

          &:hover, &:active, &:focus {
            background-color: @primary-color;
            color: @white;
          }
        }
      }
    }
  }
}


.section__related-search-results {
  h2 {
    font-size: clamp(1rem, 2vw, 1.3rem);
  }

  .section__category-categories-list {
    font-size: clamp(0.8rem, 2vw, 1rem);

    ul {
      column-count: 5 !important;

      @media all and @mobile-to-tablet {
        column-count: 4 !important;
      }

      @media all and @to-mobile {
        column-count: 2 !important;
      }
    }
  }
}

.section_filter-button {
  width: 100%;
  background-color: @darken-20-primary-color;
  border-top: 2px solid @primary-color;
  border-bottom: 2px solid @primary-color;
  z-index: 1;
  padding: .5rem 0;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: @primary-color;
  }

  i {
    padding-right: 8px;
  }
}

.success-msg {
  border: solid @green 2px;
  background: @darken-30-green;
  color: @lighten-10-green;

  a {
    color: @lighten-10-green;
  }

  span {
    background-color: @green;
    color: @black;
  }
}


.info-msg {
  border: solid @blue 2px;
  background: @darken-30-blue;
  color: @lighten-40-blue;

  a {
    color: @darken-20-blue;
  }

  span {
    background-color: @blue;
    color: @white;
  }
}

.error-msg {
  border: solid @orange 2px;
  background: @darken-40-orange;
  color: @lighten-10-orange;


  a {
    color: @darken-20-orange;
  }

  span {
    background-color: @orange;
    color: @black;
  }
}

.errorlist {
  list-style: none;
}

.notification-message {
  list-style: none;
  display: block;
  padding: 1rem;
  list-style-type: none;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  position: relative;

  a {
    text-decoration: underline;
  }

  span {
    position: absolute;
    top: -11px;
    left: 1rem;
    padding: .2rem .5rem;
  }
}

.page-error {
  .notification-message {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.force-blur {
  filter: blur(20px) !important;
}
