/* CATEGORY-SECTIONS
-----------------------------------------------*/
@import (less) "../mixins/colors.less";
@import (less) "../mixins/responsive.less";

.section__category {
    overflow-x: hidden;
    overflow-y: visible;

    .tns-outer {
        position: relative;
        overflow: hidden;
        display: block;

        .prev, .next {
            outline: none;
            width: 7% !important;
            height: 100%;
            position: absolute;
            top: 0%;
            border: 0px solid black;
            font-size: 3rem;
            z-index: 3;
            display: flex;
            place-content: center;

            @media all and @to-mobile {
                width: 15% !important
            }

            &:before {
                margin: 0 auto;
                top: 46%;
                position: absolute;
                color: white;
            }
        }

        .prev {
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }

        .next {
            right: 0;
            background: rgb(0, 0, 0);
            background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6026785714285714) 40%, rgba(0, 0, 0, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
        }
    }

    .section__category-carousel {
        row-gap: 8px;
        -moz-column-gap: 8px;
        column-gap: 8px;
        position: relative;
        display: flex;
        flex-direction: row;

        .category-item {
            aspect-ratio: 16/9;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 1;
            width: 100%;

            a {
                text-decoration: none;
                font-style: normal;

                .category-item-inner {
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        color: transparent;
                        text-indent: -100%;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .title-category {
                        position: absolute;
                        bottom: -1px;
                        right: 0;
                        width: 100%;
                        height: 40%;
                        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
                        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
                        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
                        color: #fff;
                        display: flex;
                        align-items: flex-end;
                        justify-content: flex-end;
                        flex-direction: column;
                        align-items: center;
                        font-family: 'Montserrat', sans-serif;

                        h3 {
                            font-size: clamp(1.1rem, 3vw, 1.6rem);
                            font-weight: bold;
                            color: white;
                            text-decoration: none;
                            margin: 0 auto;
                            position: relative;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        p {
                            color: @primary-color;
                            font-size: clamp(1.1rem, 2vw, 1.4rem);
                            font-weight: 600;
                            position: relative;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }

    // CAROUSEL
    .section__category-carousel {
        margin: 0 auto;
        z-index: 1;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        //padding-top: 100px;
        //padding-bottom: 100px;

        .container {
            padding: 0;
        }

        .center {
            width: 100%;
            transform: scale(1.5) !important;
            z-index: 5;
            transition: cubic-bezier(.37, .37, 0, 1);
            transition-duration: 0.3s;
        }
    }
}

.section__category-list {
    .section__category-wrapper {
        max-width: 1500px;
        margin: 0 auto;

        .section__category-filters {
            display: flex;
            flex-direction: row;

            .input-group-prepend {
                border: 2px solid @primary-color;
                position: relative;
                display: flex;
                flex-direction: row;
                color: @fade-30-white;

                span {
                    height: 100%;
                    padding: 0 0.8rem;
                    border-right: 2px solid @primary-color;
                    display: flex;
                    align-items: center;
                }

                input {
                    padding-left: 0.8rem;
                    height: 100%;
                    width: 100%;
                    color: @fade-50-white;
                    font-size: 1rem;
                    padding-right: 0.5rem;
                }
            }

            .filters {
                display: flex;
                flex-direction: row;
                justify-content: center;


                p {
                    color: white;
                    font-weight: normal;
                    margin: 0.5rem;
                    align-self: center;
                }

                button {
                    font-size: 1rem;
                    margin: 0 0.5rem;
                    border: 2px solid @primary-color;
                    color: @primary-color;
                    padding: 0.8rem 0.8rem;
                    background-color: transparent;
                    outline: none !important;
                    font-weight: bold;
                    -webkit-transition-duration: 0.2s;
                    transition-duration: 0.2s;

                    &:hover {
                        background-color: @primary-color;
                        color: white;
                    }
                }

                .active {
                    background-color: @primary-color;
                    color: white;
                }
            }

            @media all and @mobile-to-tablet {
                display: flex;

                .filters {
                    p {
                        text-align: right;
                    }
                }

            }

            @media all and @to-mobile {
                display: flex;
                flex-direction: column;

                .input-group-prepend {
                    height: 46px;
                }

                .filters {
                    display: flex;
                    flex-direction: column;

                    button {
                        margin: 0.2rem 0rem;
                    }
                }
            }
        }
    }

    .section__category-categories-list {
        font-size: clamp(0.8rem, 2vw, 1rem);
        margin-top: 30px;

        ul {
            column-count: 6;
            padding: 0;
            margin: 0;
            list-style: none;

            @media all and @mobile-to-tablet {
                column-count: 4;
            }

            @media all and @to-mobile {
                column-count: 2;
            }

            li {
                color: white;
                text-transform: capitalize;
                margin-bottom: 15px;

                a {
                    color: #FFFFFF;
                    text-decoration: none;

                    span {
                        color: @primary-color;

                        i {
                            margin: 0 5px;

                        }
                    }
                }
            }
        }
    }
}