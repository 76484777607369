/* THEME
-----------------------------------------------*/
@import (less) "colors.less";

html {
    background-color: @black;
    -webkit-font-smoothing: antialiased;
    image-rendering: crisp-edges;
    background-color: @black;

}

body {
    background-color: @black;
    font-family: 'Montserrat', sans-serif;
    color: @white;
}

h1,
h2,
h3 {
    padding: 0;
    margin: 0;
}



h1 {
    font-size: clamp(0.6rem, 2vw, 1rem);
}

h2 {
    font-size: clamp(0.6rem, 2vw, 1rem);
}

.border-bottom-primary {
    border-bottom: @primary-color solid 2px;
}

.border-top-primary {
    border-top: @primary-color solid 2px;
}

.border-bottom-grey {
    border-bottom: @fade-20-white solid 2px;
}

.border-top-grey {
    border-top: @fade-20-white solid 2px;
}

.border-vertical-grey {
    border-top: @fade-20-white solid 2px;
    border-bottom: @fade-20-white solid 2px;
}

.border-horizontal-grey {
    border-right: @fade-20-white solid 2px;
    border-left: @fade-20-white solid 2px;
}

.border-both-grey {
    border-right: @fade-20-white solid 2px;
    border-left: @fade-20-white solid 2px;
}

.promo-widget-enabled .top_spacer {
    opacity: 0;
    margin: 0;
    padding: 0;
    padding-top:0px;

}
.top_spacer {
    opacity: 0;
    padding: 0;
    margin: 0;
    padding-top: 51px;
}

.bottom_spacer {
    padding-bottom: 100px;
}

a {
    text-decoration: none;
    color: @white;
}

ul,
li {
    padding: 0;
    margin: 0;
}

.justify-start {
    justify-content: start;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.background-primary {
    background-color: @primary-color;
}


/* HORIZONTAL VERTICAL SPACING */
.spacing-horizontal-small {
    padding-right: 1rem;
    padding-left: 1rem;
}

.spacing-horizontal-average {
    padding-right: 2rem;
    padding-left: 2rem;
}

.spacing-horizontal-big {
    padding-right: 3rem;
    padding-left: 3rem;
}

.spacing-vertical-small {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.spacing-vertical-average {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.spacing-vertical-big {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

/* BOTH SPACING */

.spacing-both-small {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
}

.spacing-both-average {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.spacing-both-big {
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
    padding-left: 3rem;
}


/* TOP - LEFT SPACING */
.spacing-left-horizontal-small {
    padding-left: 1rem;
}

.spacing-left-horizontal-average {
    padding-left: 2rem;
}

.spacing-left-horizontal-big {
    padding-left: 3rem;
}

.spacing-top-vertical-small {
    padding-top: 1rem;
}

.spacing-top-vertical-average {
    padding-top: 2rem;
}

.spacing-top-vertical-big {
    padding-top: 3rem;
}

/* BOTTOM - RIGHT SPACING */
.spacing-right-horizontal-small {
    padding-right: 1rem;
}

.spacing-right-horizontal-average {
    padding-right: 2rem;
}

.spacing-right-horizontal-big {
    padding-right: 3rem;
}

.spacing-bottom-vertical-small {
    padding-bottom: 1rem;
}

.spacing-bottom-vertical-average {
    padding-bottom: 2rem;
}

.spacing-bottom-vertical-big {
    padding-bottom: 3rem;
}

.link {
    color: @lighten-10-primary-color;
    font-weight: 600;
    text-decoration: underline;
}

.grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: transform;
    transition-property: transform;

    &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
}

button {
    outline: none;
    cursor: pointer;
}

input[type="search"]::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
    /*Your new styles */
    height: 13px;
    width: 13px;
    background: url("data:image/svg+xml;charset=UTF-8,%3csvg viewPort='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='1' y1='11' x2='11' y2='1' stroke='white' stroke-width='2'/%3e%3cline x1='1' y1='1' x2='11' y2='11' stroke='white' stroke-width='2'/%3e%3c/svg%3e");
}