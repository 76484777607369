.footer {
  margin: 0 auto;
  width: 100%;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  &__links {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__link {
    font-size: 12px !important;
    display: flex;
    text-decoration: underline;
    border-radius: 10px;
    color: @primary-color;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  &__text {
    font-size: 12px !important;
    color: white;
    width: 100%;
    font-weight: bold;

    a {
      color:white;
    }
  }
}
